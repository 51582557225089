import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../types/types';
import { obtenerColorPorPuntos, separarNumeroYTexto } from './TablaRubricaDetail7';
import { BaseParSeguimiento } from '../../../redux/pdfSombra/pdfSombraActions';
import pdfSombraCheck from '../../../assets/pdfSombraCheck.svg';
import pdfSombraCross from '../../../assets/pdfSombraCross.svg';

export const orderBaseParSeguimiento = (questions?: BaseParSeguimiento[]) => {
  if (questions) {
    const orderedQuestions = questions.sort((a, b) => a.pregunta - b.pregunta);
    const rango1 = orderedQuestions.filter((p) => p.pregunta >= 0 && p.pregunta <= 10);
    const rango2 = orderedQuestions.filter((p) => p.pregunta >= 11 && p.pregunta <= 14);
    const resto = orderedQuestions.filter((p) => p.pregunta > 14);
    return {
      rango1,
      rango2,
      resto
    };
  }
  return { rango1: [], rango2: [], resto: [] };
};

const ContentRow = (result: BaseParSeguimiento, rowKey: number, name: string) => {
  return (
    <>
      <div className="row" style={{ alignItems: 'center' }} key={`${rowKey}-${name}`}>
        <div className="col ">{result.texto_pregunta}</div>

        <div className={`col-1 col-title center`}>
          <div className="row" style={{ alignItems: 'center', justifyContent: 'center' }}>
            <div
              className={`pills ${obtenerColorPorPuntos(result.respuesta === 'Si' ? '3' : '1')}`}
            >
              {result.respuesta === 'Si' ? (
                <img src={pdfSombraCheck} alt={'pdfSombraCheck'} />
              ) : (
                <img src={pdfSombraCross} alt={'pdfSombraCross'} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="dvider light" />
    </>
  );
};

const TablaRubricaDetailPar9 = memo(() => {
  const { shadowProcess } = useSelector((state: AppState) => state.pdfSombra);

  const { rango1, rango2, resto } = orderBaseParSeguimiento(shadowProcess?.base_par.seguimiento);

  return (
    <div className="table-five content">
      <div className="banner"></div>
      <div className="row">
        <div className="col center">
          <h1>Rúbrica de Evaluación Par Experto</h1>
        </div>
      </div>

      <div className="row">
        <div className="col col-title">
          El colaborador pudo realizar las siguientes actividades:
        </div>
      </div>
      <div className="dvider" />

      <div className="row" style={{ marginTop: '18px' }}>
        <div className="col col-title">Servicio y seguimiento al cliente</div>
        <div className="col-1 col-title center">Checklist</div>
      </div>

      {rango1.map((result, key) => {
        return ContentRow(result, key, 'rango1');
      })}

      <div className="row">
        <div className="col col-title" style={{ marginTop: '18px' }}>
          Herramientas
        </div>
        <div className="col-1 col-title center"></div>
        <div className="col-2 col-title center"></div>
      </div>
      {rango2.map((result, key) => {
        return ContentRow(result, key, 'rango2');
      })}
      
      <div className="row">
        <div className="col col-title" style={{ marginTop: '18px' }}>
          Formatos
        </div>
        <div className="col-1 col-title center"></div>
        <div className="col-2 col-title center"></div>
      </div>
      {resto.map((result, key) => {
        return ContentRow(result, key, 'resto');
      })}
    </div>
  );
});
TablaRubricaDetailPar9.displayName = 'TablaRubricaDetailPar9';

export default TablaRubricaDetailPar9;

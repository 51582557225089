import React, { useEffect, useRef, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUserSuccess, getUserFailure } from '../../redux/user/userActions';
import './header.css';
import logoGentera from '../../assets/logogentera.svg';
import leftArrows from '../../assets/angles-left.png';
import rightArrows from '../../assets/angles-right.png';
import UserFallback from '../../assets/user-fallback.svg';
import QuestionMark from '../../assets/circle-info.svg';
import Bell from '../../assets/bell.svg';
import NotificationModal from '../../components/notificacionHeader/NotificationModal';
import ChangePasswordModal from '../../components/resetPassword/changePasswordModal';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { getUser } from '../../services/getUser';
import { marcarNotificacionesLeidas } from '../../services/marcarNotificacionesLeidas';
import { getNotificaciones } from '../../services/getNotificaciones';
import SettingsIcon from '@mui/icons-material/Settings';
import { AppState, AgendaItem } from '../../types/types';
import UploadModal from '../../components/uploadModal/uploadModal';
import Upload from '../../assets/upload-logo.png';
import { uploadExcelAgenda } from '../../services/uploadExcelAgenda';
import { useSelector } from 'react-redux';
import agendaStyles from '../../styles/agenda.module.css';
import SuccessLogo from '../../assets/success-logo.png';
import Quit from '../../assets/quit.png';
import ErrorLogo from '../../assets/error-logo.png';
import Menu from '../menu/menu';

type menuCollapseState = {
  onMenuChange: (collapsed: boolean) => void;
};

const dashboardHeaders = {
  '/colaboradores': 'Colaboradores Compartamos Banco',
  '/grupos': 'Grupos de capacitación',
  '/sedes': 'Sedes',
  '/hoteles': 'Hoteles',
  '/yastas': 'Yastas',
  '/trackAnual': 'Track Capacitación',
  '/lyf': 'Líderes y Formadores',
  '/formadores': 'Formadores',
  '/agenda': 'Detalle de agenda',
  '/formadores/agenda/:id': 'Detalle de agenda',
  '/trackAnual//detalledeTrackCapacitacion/:nomina': 'Detalle de track Capacitación'
};

function Header({ onMenuChange }: menuCollapseState) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const [menuCollapsed, setMenuCollapsed] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>(null);
  const location = useLocation();
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState<boolean>(false);
  const [userNameWidth, setUserNameWidth] = useState(0);
  const userNameRef = useRef<HTMLParagraphElement>(null);
  const [showMenuModal, setShowMenuModal] = useState(false);
  const [actionName, setActionName] = useState<string>('');
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [uploadingMessage, setUploadingMessage] = useState('Cargando Datos...');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const usuarioRol = useSelector((state: AppState) => state.user.userData?.role);

  const closeAlert = () => {
    setIsUploading(false);
    setUploadSuccess(false);
    setUploadError(false);
  };

  const handleMouseEnter = () => {
    setIsMenuOpen(true);
  };

  const handleMouseLeave = () => {
    setIsMenuOpen(false);
  };

  const handleCargarAgendaClick = () => {
    setActionName('Agenda');
    setUpdateModal(true);
    setIsNotificationModalOpen(false);
    setIsChangePasswordModalOpen(false);
    setShowMenuModal(false);
  };

  const onClose = () => {
    setUpdateModal(false);
  }

  const handleFileUpload = async (base64Data: string, fileName: string) => {
    setLoadingData(true);
    setIsUploading(true);
    setUploadingMessage('Cargando Datos...');

    setTimeout(() => {
      setLoadingData(false);
      setUploadingMessage('Se subirá el excel pronto');

      setTimeout(async () => {
        const token = sessionStorage.getItem('token');

        if (!token) {
          setErrorMessage('Error: No se encontró el token de sesión.');
          setUploadError(true);
          setIsUploading(false);
          setTimeout(closeAlert, 2000);
          return;
        }

        let response;
        try {
          if (actionName === 'Agenda') {
            response = await uploadExcelAgenda(base64Data, token);
          } else {
            throw new Error('Acción no reconocida');
          }

          setIsUploading(false);

          if (response && response.success) {
            setSuccessMessage(`La subida del archivo ${fileName} fue exitosa.`);
            setUploadSuccess(true);
          } else {
            setErrorMessage(response.message || 'Error en la carga del archivo.');
            setUploadError(true);
          }
        } catch (error) {
          const errorMessage =
            error instanceof Error ? error.message : 'Error inesperado en la carga del archivo.';
          setErrorMessage(errorMessage);
          setUploadError(true);
        } finally {
          setTimeout(closeAlert, 2000);
        }
        setIsUploading(false);
      }, 2000);
    }, 2000);
  };

  useEffect(() => {
    const storedToken = sessionStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
      getUser(storedToken)
        .then((result) => {
          setUserData(result);
          dispatch(getUserSuccess(result));
        })
        .catch((error) => {
          dispatch(getUserFailure(error));
        });
    }
  }, [dispatch]);

  useEffect(() => {
    const fetchNotificacionesPeriodicamente = async () => {
      if (token) {
        try {
          const response: any = await getNotificaciones(token);
          const notificaciones = response.notificaciones || [];
          const notificacionesNoLeidas = notificaciones.filter(
            (n: { leida: boolean }) => !n.leida
          ).length;
          setUnreadNotificationsCount(notificacionesNoLeidas);
        } catch (error) {
          console.error('Error fetching notifications:', error);
        }
      }
    };

    const intervalId = setInterval(fetchNotificacionesPeriodicamente, 3000);

    return () => clearInterval(intervalId);
  }, [token]);

  const openSettingsModal = () => {
    setIsChangePasswordModalOpen(true);
    
    setUpdateModal(false);
    setIsNotificationModalOpen(false);
    setShowMenuModal(false);
  };

  const openNotificationModal = () => {
    if (token) {
      marcarNotificacionesLeidas(token)
        .then(() => {
          console.log('Notificaciones marcadas como leídas');
          setUnreadNotificationsCount(0);
        })
        .catch((error) => {
          console.error('Error al marcar notificaciones como leídas', error);
        });
    }

    setIsNotificationModalOpen(true);

    setUpdateModal(false);
    setIsChangePasswordModalOpen(false);
    setShowMenuModal(false);
  };

  const cleaningToken = () => {
    sessionStorage.removeItem('token');
    localStorage.removeItem('user');
    setToken(null);
    window.location.href = '/login';
  };

  const collapsingArrow = () => {
    setMenuCollapsed(!menuCollapsed);
    onMenuChange(!menuCollapsed);
  };

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    //console.log('tomando token', token);
    if (token) {
      getUser(token)
        .then((result) => {
          setUserData(result);
          dispatch(getUserSuccess(result));
        })
        .catch((error) => {
          dispatch(getUserFailure(error));
          console.error('Error al obtener usuario', error);
        });
    }
  }, []);

  useEffect(() => {
    if (userNameRef.current !== null) {
      const newWidth = userNameRef.current.offsetWidth + 15; // Aumentar en dos píxeles adicionales
      setUserNameWidth(newWidth);
    }
  }, [isMenuOpen]);

  const headerTitle = useMemo(() => {
    // Manejo explícito de rutas parametrizadas
    if (location.pathname.startsWith('/formadores/agenda/')) {
      return 'Detalle de agenda';
    }
    if (location.pathname.startsWith('/trackAnual/detalledeTrackCapacitacion/')) {
      return 'Detalle de Track Capacitación';
    }

    // Intenta encontrar un título directamente en el objeto dashboardHeaders para rutas no parametrizadas
    const matchedKey = Object.keys(dashboardHeaders).find(
      (key) => location.pathname === key || location.pathname.startsWith(key)
    );
    if (matchedKey) {
      return dashboardHeaders[matchedKey as keyof typeof dashboardHeaders];
    }

    // Título por defecto si no se encuentra una coincidencia específica
    return 'Bienvenido';
  }, [location.pathname]);

  return (
    <div className='bootstrap-wrapper'>
      <div className="header-container">
        {updateModal && (
          <UploadModal
            onClose={onClose}
            uploadService={handleFileUpload}
            title={`Cargar Archivo de ${actionName.charAt(0).toUpperCase() + actionName.slice(1)}`}
            isLoading={isUploading}
            setIsLoading={setLoadingData}
            successCallback={(message) => {
              console.log(message);
            }}
            errorCallback={(message) => {
              console.error(message);
            }}
          />
        )}
        <div className="logo-container">
          <img src={logoGentera} alt="logo Gentera" className="logo" />
          <img
            src={menuCollapsed ? rightArrows : leftArrows}
            alt="collapsing arrows"
            className="collapsing-arrows hidden-md-down"
            onClick={collapsingArrow}
          />
        </div>
        <div className='hidden-md-up' style={{
          color: 'white',
          display: 'inline-flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '16px'
        }}>
          <span style={{
            cursor: 'pointer'
          }} onClick={() => setShowMenuModal(true)}>
            <svg width="32" height="32" fill="currentColor" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
            </svg>
          </span>
        </div>
        <div className="header-wrapper hidden-md-down">
          <h1 className="header-title">{headerTitle}</h1>
          <img
            height={24}
            width={24}
            src={QuestionMark}
            alt="Info"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Help and information"
          />
          <Tooltip id="my-tooltip" className="tooltip" />
        </div>

        {userData ? (
          <div className="profile-container hidden-md-down">
            {usuarioRol === 'admin' && (
              <div className={agendaStyles['imgPointer']} onClick={handleCargarAgendaClick}>
                <img
                  src={Upload}
                  alt="upload-logo"
                  style={{
                    filter: 'brightness(10000%)'
                  }}
                />
                <p
                  style={{
                    marginLeft: '5px',
                    color: 'white'
                  }}>
                  Actualizar Agenda
                </p>
              </div>
            )}
            {userData.user.role === 'admin' && (
              <div className="settings-icon-container" onClick={openSettingsModal}>
                <SettingsIcon style={{ color: 'white', cursor: 'pointer' }} />
              </div>
            )}
            <div className="notification-bell-container" onClick={openNotificationModal}>
              <img src={Bell} alt="Notifications" height={24} width={24} />
              {unreadNotificationsCount > 0 && (
                <span className="notification-count">{unreadNotificationsCount}</span>
              )}
            </div>
            <div className="user-menu"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}>
              <img src={userData.user.foto ?? UserFallback} alt="User" height={24} width={24} />
              <p className="user-name" ref={userNameRef}>
                {(userData.user.nombre as string).toLowerCase()}
              </p>
              {isMenuOpen && (
                <div className="submenu" style={{ width: userNameWidth }}>
                  {/* Contenido del submenu */}
                  <ul>
                    <li onClick={cleaningToken}>Cerrar sesión</li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        ) : (
          <p className="user-name" onClick={cleaningToken}>
            Iniciar Sesión
          </p>
        )}
        {isNotificationModalOpen && token && (
          <NotificationModal
            onClose={() => setIsNotificationModalOpen(false)}
            token={token}
            isOpen={isNotificationModalOpen}
          />
        )}
        {isChangePasswordModalOpen && token && (
          <ChangePasswordModal
            isOpen={isChangePasswordModalOpen}
            onClose={() => setIsChangePasswordModalOpen(false)}
            token={token}
          />
        )}

        {isUploading && (
          <div className="upload-message-container loading-data">
            {' '}
            {/* Use "loading-data" for info messages */}
            <div className="update-file-message">
              <img src={QuestionMark} />
              <p>{uploadingMessage}</p>
              <img src={Quit} alt="quit-logo" onClick={closeAlert} className="closse-alert" />
            </div>
          </div>
        )}
        {uploadSuccess && (
          <div className="upload-message-container success">
            <div className="update-file-message">
              <img src={SuccessLogo} alt="success-logo" />
              <p>{successMessage}</p>
              <img src={Quit} alt="quit-logo" onClick={closeAlert} className="closse-alert" />
            </div>
          </div>
        )}
        {uploadError && (
          <div className="upload-message-container error">
            <div className="update-file-message">
              <img src={ErrorLogo} alt="error-logo" />
              <p>{errorMessage}</p>
              <img src={Quit} alt="quit-logo" onClick={closeAlert} className="closse-alert" />
            </div>
          </div>
        )}
      </div>
      {/* menu modal */}
      {
        (showMenuModal) && (
          <div style={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100vh',
            margin: '0px',
            padding: '0px',
            boxSizing: 'border-box',
            overflowX: 'hidden',
            overflowY: 'auto',
            backgroundColor: 'rgba(1,2,3,0.9)',
            zIndex: 100,
            color: 'white'
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              padding: '16px'
            }}>
              <span style={{ cursor: 'pointer' }} onClick={() => setShowMenuModal(false)}>
                <svg width="32" height="32" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                </svg>
              </span>
            </div>
            <div style={{
              padding: '8px'
            }}>
              <div className="header-wrapper">
                <h1 className="header-title">{headerTitle}</h1>
                <img
                  height={24}
                  width={24}
                  src={QuestionMark}
                  alt="Info"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Help and information"
                />
                <Tooltip id="my-tooltip" className="tooltip" />
              </div>
              {userData ? (
                <div className="profile-container">
                  {usuarioRol === 'admin' && (
                    <div className={agendaStyles['imgPointer']} onClick={handleCargarAgendaClick}>
                      <img
                        src={Upload}
                        alt="upload-logo"
                        style={{
                          filter: 'brightness(10000%)'
                        }}
                      />
                      <p
                        style={{
                          marginLeft: '5px',
                          color: 'white',
                          width:'171px'
                        }}>
                        Actualizar Agenda
                      </p>
                    </div>
                  )}
                  {userData.user.role === 'admin' && (
                    <div className="settings-icon-container" onClick={openSettingsModal}>
                      <SettingsIcon style={{ color: 'white', cursor: 'pointer' }} />
                    </div>
                  )}
                  
                  <div className="notification-bell-container" onClick={openNotificationModal}>
                    <img src={Bell} alt="Notifications" height={24} width={24} />
                    {unreadNotificationsCount > 0 && (
                      <span className="notification-count">{unreadNotificationsCount}</span>
                    )}
                  </div>

                  <div className="user-menu"
                    onClick={() => setIsMenuOpen((prev: boolean) => (!prev))} >
                    <img src={userData.user.foto ?? UserFallback} alt="User" height={24} width={24} />
                    <p className="user-name" ref={userNameRef}>
                      {(userData.user.nombre as string).toLowerCase()}
                    </p>
                    {isMenuOpen && (
                      <div className="submenu" style={{ width: userNameWidth }}>
                        {/* Contenido del submenu */}
                        <ul>
                          <li onClick={cleaningToken}>Cerrar sesión</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <p className="user-name" onClick={cleaningToken}>
                  Iniciar Sesión
                </p>
              )}
              {/* main menu */}
              <div style={{
                color: 'white'
              }} onClick={(e) => {
                e?.stopPropagation();
                e?.preventDefault();
              }}>
                <Menu menuState={false} isMobile={false} />
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
}

export default Header;
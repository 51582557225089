import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../types/types';

const Tabla2: React.FC = () => {
  const { shadowProcess } = useSelector((state: AppState) => state.pdfSombra);
  return (
    <div className="table-two content">
      <div className="row">
        <div className="col col-title">Semana 2</div>
        <div className="col col-title">Semana 3</div>
        <div className="col col-title">Semana 4</div>
      </div>

      <div className="row">
        <div className="col">
          <div className="card orange">Curso presencial</div>
        </div>
        <div className="col">
          <div className="card orange">Sombra / Experto Par</div>
        </div>
        <div className="col">
          <div className="card orange">Sombra / Acompañamiento Líder</div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="card green">ILT - Aprobado</div>
        </div>
        <div className="col">
          <div className="card green">Realizado</div>
        </div>
        <div className="col">
          <div className="card green">Realizado</div>
        </div>
      </div>

      <div className="row">
        <div className="col center">
          Calificación <br /> <div className='results'>{shadowProcess?.base_par.calificacion}</div>
        </div>
        <div className="col"></div>
        <div className="col center">
          Resultado Total <br /> <div className='results'>{shadowProcess?.base_lider.calificacion}</div>
        </div>
      </div>
    </div>
  );
  /*  return (
    <table style={{ borderCollapse: 'collapse', width: '100%', margin: "20px 0"  }}>
      <thead>
        <tr>
          <th colSpan={2} style={{ border: '1px solid black', padding: '8px',  width: '33.33%' }}>Semana 2</th>
          <th style={{ border: '1px solid black', padding: '8px',  width: '33.33%' }}>Semana 3</th>
          <th colSpan={2} style={{ border: '1px solid black', padding: '8px',  width: '33.33%' }}>Semana 4</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={2} style={{ border: '1px solid black', padding: '8px' }}>Curso presencial</td>
          <td style={{ border: '1px solid black', padding: '8px' }}>Sombra / Experto Par</td>
          <td colSpan={2} style={{ border: '1px solid black', padding: '8px' }}>Sombra / Acompañamiento Líder</td>
        </tr>
        <tr>
          <td colSpan={2}  style={{ border: '1px solid black', padding: '8px' }}>ILT-Aprobado</td>
          <td style={{ border: '1px solid black', padding: '8px' }}>Fila 4, Col 2</td>
          <td colSpan={2}  style={{ border: '1px solid black', padding: '8px' }}>Fila 4, Col 3 - Parte 2</td>
        </tr>
        <tr>
          <td colSpan={2} style={{ border: '1px solid black', padding: '8px' }}>ILT-Aprobado</td>
          <td rowSpan={2} style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Realizado</td>
          <td colSpan={2} style={{ border: '1px solid black', padding: '8px' }}>Realizado</td>
        </tr>
        <tr>
            <td style={{ border: '1px solid black', padding: '8px' }}>Calificación</td>
          <td style={{ border: '1px solid black', padding: '8px' }}>97</td>
          <td style={{ border: '1px solid black', padding: '8px' }}>Resultado Total</td>
          <td style={{ border: '1px solid black', padding: '8px' }}>40</td>
        </tr>
        
      </tbody>
    </table>
  ); */
};
Tabla2.displayName = 'Tabla2';

export default Tabla2;

import React, { useEffect, memo } from 'react';
import { Chart } from 'chart.js';
import { useSelector } from 'react-redux';
import { AppState } from '../../../types/types';

const recomendation = [
  'Requiere supervisión constante y modelaje diario de las actividades en donde obtuvo cero (0) puntos, es fundamental tu acompañamiento, mínimo durante un mes, para garantizar su correcta operación. No olvides supervisar y retroalimentar aquellas actividades en donde tuvo un (1) punto de calificación. El colaborador iniciará el proceso de Tutoría en campo, recibirán instrucciones por parte del equipo de Talento.',
  'Supervisa, modela y acompaña al colaborador en las actividades que sacó 0 o 1 punto. Recomendación: acompáñalo 2 veces a la semana durante un mes para observar las actividades donde salió más bajo',
  'Acompaña a tu colaborador una vez a la semana durante un mes y refuerza las actividades que consideres necesarias, sobre todo en aquellas que tuvo 1 punto. Adicional al acompañamiento, resuelve sus dudas y dale retroalimentación.'
];
export const returnRecomentadion = (shadowProcess:any) => {
  const result = shadowProcess?.base_lider.calificacion;
  if (!result) return '-';
  if (result < 31) return recomendation[0];
  if (result > 50) return recomendation[2];
  return recomendation[1];
};
const TableGraph3 = memo(() => {
  const { shadowProcess } = useSelector((state: AppState) => state.pdfSombra);
  console.log(shadowProcess);



  useEffect(() => {
    const dataValues = [shadowProcess?.base_lider.formatos, shadowProcess?.base_lider.servicio, shadowProcess?.base_lider.herramientas];
    const labels = ['Formatos', 'Servicio al cliente', 'Herramientas'];
    const labeledData = labels.map((label, index) => `${label}, ${dataValues[index]}`);
    const chart: any = document.getElementById('radialChart');
    const chartStatus = Chart.getChart('radialChart');
    if (chart) {
      const ctx: any = chart.getContext('2d');
      if (!ctx) return;
      if (chartStatus !== undefined) chartStatus.destroy();
      new Chart(ctx, {
        type: 'radar',
        data: {
          labels: labeledData,
          datasets: [
            {
              label: 'Puntuación',
              data: dataValues,
              backgroundColor: '#00ae9d6e', // Color de fondo con transparencia
              borderColor: '#006990', // Color del borde del polígono
              borderWidth: 1
            }
          ]
        },
        options: {
          scales: {
            r: {
              min: 0,
              max: 4,
              ticks: {
                stepSize: 1,
                display: false // Oculta los números en las líneas de escala
              },
              grid: {
                color: '#BFC3C6' // Color de las líneas del fondo
              }
            }
          },
          plugins: {
            legend: {
              display: false // Oculta la leyenda
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  return `${context.label}: ${context.raw}`; // Etiquetas personalizadas
                }
              }
            }
          }
        }
      });
    }
  }, [shadowProcess]);
  return (
    <div className="table-three content">
      <div className="row">
        <div className="col">
          <h1>Desempeño del colaborador por Categoría</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-5">
          <canvas id="radialChart" width="500" height="500"></canvas>
        </div>
        <div className="col-5 tabla-container">
          <table className="tabla-desempeno">
            <thead>
              <tr>
                <th colSpan={2}>Escala de desempeño</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{color:'#00AE9E'}}>1</td>
                <td style={{color:'#5E6A71'}}>Debe mejorar</td>
              </tr>
              <tr>
                <td style={{color:'#00AE9E'}}>2</td>
                <td style={{color:'#5E6A71'}}>Aceptable</td>
              </tr>
              <tr>
                <td style={{color:'#00AE9E'}}>3</td>
                <td style={{color:'#5E6A71'}}>Excelente</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col col-title">
        Recomendaciones:
        </div>
      
      </div>
      <div className="row">
        <div className="col">
        {returnRecomentadion(shadowProcess)}
        </div>
      </div>
        <div className="dvider"></div>
    </div>
  );
  /*  return (
    <table border={1} style={{ margin: '20px 0' }}>
      <tbody>
        <tr>
          <td style={{ width: '50%', textAlign: 'center' }}>
            Valoración Sombra Líder por Categoría
          </td>
          <td style={{ width: '50%', textAlign: 'center' }}>Recomendación</td>
        </tr>
        <tr>
          <td style={{ width: '0%', alignItems: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ maxWidth: '80%' }}>
             
                <canvas id="radialChart" width="500" height="500"></canvas>
              </div>
            </div>
          </td>
          <td style={{ width: '50%' }}>
            Supervisa, modela y acompaña al colaborador en las actividades que sacó 0 o 1 punto.
            Recomendación: {returnRecomentadion()}
          </td>
        </tr>
      </tbody>
    </table>
  ); */
});
TableGraph3.displayName = 'TableGraph3';

export default TableGraph3;

import React, { memo, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import CapacitacionStyles from '../../styles/Detalles.module.css';
import grayStar from '../../assets/grayStar.svg';
import greenStar from '../../assets/greenStar.svg';
import yellowStar from '../../assets/yellowStar.svg';

import moment from 'moment';
import '../../styles/detallesCapacitacion.scss';
import CardL from './CardL';

export type LevelCardsI = any;
const LevelCards = memo(({ avances, colaborador }: LevelCardsI) => {
  const [itemsPerPage] = useState(6);
  const [currentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCourses =
    avances && avances.length > 0 ? avances.slice(indexOfFirstItem, indexOfLastItem) : [];

  const totalCourses = avances.length;

  const showFillerItems =
    totalCourses > currentCourses.length && currentCourses.length < itemsPerPage;
  const fillerItemsNeeded = showFillerItems ? itemsPerPage - (totalCourses % itemsPerPage) : 0;
  const fillerItems = Array.from({ length: fillerItemsNeeded }, (_, index) => (
    <div key={`filler-${index}`} className={CapacitacionStyles.gridItem}>
      <p>No hay cursos registrados</p>
    </div>
  ));

  const getPercentageCard2 = () => {
    const online = avances[1].calificacion ? avances[1].calificacion : 'Pendiente';
    const formador = avances[2].calificacion ? avances[2].calificacion : 'Pendiente';
    if (online === 'Pendiente' && formador === 'Pendiente') return 0;
    if (online !== 'Pendiente' || formador !== 'Pendiente') return 50;
    return 100;
  };

  return (
    <div
      className={`level-cards-body ${CapacitacionStyles.gridContainer}  ${totalCourses > itemsPerPage ? 'scrollable' : ''}`}
    >
      {avances && avances.length > 0 ? (
        <div className="level-cards-container">
          {/* CARD 1 */}
          <CardL
            step="1"
            nivel={avances[0].nivel}
            porcentaje={`${avances[0].porcentaje !== undefined ? Math.round(avances[0].porcentaje) : '0'}`}
            estado={`${avances[0].estatus} - ${avances[0].estado_finalizacion}`}
            tituloFormador={
              <p>
                Curso Formador <br /> Calificación
              </p>
            }
            calificacionFormador={avances[0].calificacion ? avances[0].calificacion : 'Pendiente'}
            fecha={
              avances[0].fecha_fin
                ? `${moment.utc(avances[0].fecha_fin).format('DD[/]MM[/]YYYY')}`
                : 'Sin Fecha'
            }
            sombraExperto={`${colaborador?.proceso_sombra_status?.base_par ? 'Realizado' : 'Sin Realizar'}`}
            sombraLider={`${colaborador?.proceso_sombra_status?.base_lider ? 'Realizado' : 'Sin Realizar'}`}
            colaborador={colaborador}
         /*    leftImage={
              <div className="image-container">
                  <img src={getImage(avances[0].porcentaje !== undefined ? Math.round(avances[0].porcentaje) : 0)} alt={`${getImage(avances[0].porcentaje !== undefined ? Math.round(avances[0].porcentaje) : 0)}`} />
              </div>
            } */
            leftTrace
          />
          {/* CARD 2 */}
          <CardL
            step="2"
            nivel={avances[2].nivel}
            porcentaje={getPercentageCard2()}
            estado={`${avances[2].estatus} - ${avances[2].estado_finalizacion}`}
            tituloFormador={
              <p>
                Curso Formador <br /> Calificación
              </p>
            }
            calificacionFormador={avances[2].calificacion ? avances[2].calificacion : 'Pendiente'}
            tituloOnline={
              <p>
                Curso Online <br /> Estatus
              </p>
            }
            calificacionOnline={avances[1].calificacion ? avances[1].calificacion : 'Pendiente'}
            fecha={
              avances[2].fecha_fin
                ? `${moment.utc(avances[2].fecha_fin).format('DD[/]MM[/]YYYY')}`
                : 'Sin Fecha'
            }
          /*   leftImage={
              <div className="image-container">
                      <img src={getImage(getPercentageCard2())} alt={`${getImage(getPercentageCard2())}`} />
              </div>
            } */
            leftTrace
          />
          {/* CARD 3 */}
          <CardL
            step="3"
            nivel={avances[3].nivel}
            porcentaje={`${avances[3].porcentaje !== undefined ? Math.round(avances[3].porcentaje) : '0'}`}
            estado={`${avances[3].estatus} - ${avances[3].estado_finalizacion}`}
            tituloFormador={
              <p>
                Curso Formador <br /> Calificación
              </p>
            }
            calificacionFormador={avances[3].calificacion ? avances[3].calificacion : 'Pendiente'}
            fecha={
              avances[3].fecha_fin
                ? `${moment.utc(avances[3].fecha_fin).format('DD[/]MM[/]YYYY')}`
                : 'Sin Fecha'
            }
           /*  leftImage={
              <div className="image-container">
                  <img src={getImage(avances[3].porcentaje !== undefined ? Math.round(avances[3].porcentaje) : 0)} alt={`${getImage(avances[3].porcentaje !== undefined ? Math.round(avances[3].porcentaje) : 0)}`} />
              </div>
            } */
          />
        </div>
      ) : (
        <p>No hay avances de cursos para mostrar.</p>
      )}
      {fillerItems}
    </div>
  );
});
LevelCards.displayName = 'LevelCards';
export default LevelCards;

import React, { useState, useEffect } from 'react';
import Upload from '../assets/upload-logo.png';
import Filter from '../assets/filter-logo.png';
import LeftArrow from '../assets/arrow-left.png';
import RightArrow from '../assets/arrow-right.png';
import SuccessLogo from '../assets/success-logo.png';
import Quit from '../assets/quit.png';
import ErrorLogo from '../assets/error-logo.png';
import UpdateFormadoresModal from '../components/updateYastasModal/updateYastasModal';
import { getColaboradoresYastas } from '../services/getColaboradoresYastas';
import { format } from 'date-fns';
import moment from 'moment';

function Yastas() {
  const [colaboradoresYastas, setColaboradoresYastas] = useState<any>(null);
  const [updateYastasResponse, setUpdateYastasResponse] = useState<boolean | null>(null);
  const [updateYastasModal, setUpdateYastasModal] = useState<boolean>(false);
  const [rowsLimit, setRowsLimit] = useState<number | ''>(10);
  const [eventoFilter, setEventoFilter] = useState<string>('');
  const [paginationInfo, setPaginationInfo] = useState<any>(null);
  const [pagination, setPagination] = useState<number>(1);
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const isUpdateYastasModal = (openModal: boolean) => {
    setUpdateYastasModal(openModal);
  };

  //   const isfiltersModal = (openFiltersModal: boolean) => {
  //     setFiltersModal(openFiltersModal);
  //   };

  //   const isEventoFilter = (eventoFilter: string) => {
  //     setEventoFilter(eventoFilter);
  //   };

  const isPostSuccess = (postResponse: boolean) => {
    setUpdateYastasResponse(postResponse);
  };

  const isLoadingAlert = (loadAlert: boolean) => {
    setLoadingData(loadAlert);
  };

  const closeAlert = () => {
    setUpdateYastasResponse(null);
  };

  const closeLoadingAlert = () => {
    setLoadingData(false);
  };

  const handleRowsLimit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue: number | '' = e.target.value ? parseInt(e.target.value, 10) : '';
    setRowsLimit(newValue);
  };

  const nextPage = () => {
    if (pagination < paginationInfo.totalPages) {
      setPagination(pagination + 1);
    }
  };

  const previousPage = () => {
    if (pagination > 1) {
      setPagination(pagination - 1);
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const apiCall = () => {
      if (token) {
        getColaboradoresYastas(token, rowsLimit, pagination, eventoFilter)
          .then((result) => {
            setColaboradoresYastas(result.data.colaboradores);
            setPaginationInfo(result.data.pagination);
          })
          .catch((error) => {
            console.error('Error al obtener usuario', error);
          });
      }
    };
    apiCall();
    if (updateYastasResponse === true) {
      apiCall();
      setLoadingData(false);
      setTimeout(() => {
        setUpdateYastasResponse(null);
      }, 5000);
    }
    if (updateYastasResponse === false) {
      setLoadingData(false);
      setTimeout(() => {
        setUpdateYastasResponse(null);
      }, 5000);
    }
  }, [rowsLimit, eventoFilter, pagination, updateYastasResponse]);

  return (
    <div className="container colaboradores">
      <div className="table-options">
        <div className="option" onClick={() => setUpdateYastasModal(true)}>
          <img src={Upload} alt="upload-logo" />
          <p>ACTUALIZAR BASE DE DATOS</p>
        </div>
        {updateYastasModal && (
          <UpdateFormadoresModal
            isUpdateYastasModal={isUpdateYastasModal}
            isPostSuccess={isPostSuccess}
            isLoadingAlert={isLoadingAlert}
          />
        )}
        {/* {filtersModal && (
          <ColabsFilterModal isfiltersModal={isfiltersModal} isEventoFilter={isEventoFilter} />
        )} */}
        {/* <div className="option">
          <img src={Filter} alt="filter-logo" />
          <p>FILTRAR</p>
        </div> */}
      </div>
      {loadingData === true && (
        <div className="update-file-response-container">
          <div className="update-file-message loading-data">
            <img src={SuccessLogo} alt="success-logo" />
            <p>Cargando Datos...</p>
            <img src={Quit} alt="quit-logo" onClick={closeLoadingAlert} className="close-alert" />
          </div>
        </div>
      )}
      {updateYastasResponse === true && (
        <div className="update-file-response-container">
          <div className="update-file-message update-file-success">
            <img src={SuccessLogo} alt="success-logo" />
            <p>La base de datos se actualizó satisfactoriamente</p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      {updateYastasResponse === false && (
        <div className="update-file-response-container">
          <div className="update-file-message update-file-error">
            <img src={ErrorLogo} alt="error-logo" />
            <p>
              Hubo un problema con el sistema y la base de datos no se actualizó, por favor,
              inténtalo de nuevo
            </p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      {colaboradoresYastas === null ? (
        'No data'
      ) : (
        <div className="table-container scrollable-container">
          <table className="data-table-container">
            <thead className="table-header">
              <tr>
                <th>Nómina</th>
                <th className="colabs-header-2">Colaborador</th>
                <th>SD</th>
                <th>Ruta Yastas</th>
                <th>Posición</th>
                <th>Region</th>
                <th>OS Envío de materiales</th>
                <th>Celular</th>
                <th>Jefe inmediato</th>
                <th>Correo electrónico</th>
                <th>Fecha de ingreso</th>
                <th>Centro de Costos</th>
                <th>Dirección ejecutiva</th>
                <th>Última actualización</th>
                
              </tr>
            </thead>
            {colaboradoresYastas.map((item: any, index: number) => (
              <tbody key={index}>
                <tr className="data-table">
                  <td>{item.user.nomina}</td>
                  <td className="colabs-table-2">{item.user.nombre}</td>
                  <td>{item.subdireccion_yastas}</td>
                  <td>{item.ruta_yastas}</td>
                  <td>{item.posicion}</td>
                  <td>{item.region_yastas}</td>
                  <td>{item.os_materiales}</td>
                  <td>{item.phone}</td>
                  <td>{item.jefe}</td>
                  <td>{item.email}</td>
                  <td>{format(item.fecha_contratacion, 'yyyy-MM-dd')}</td>
                  <td>{item.centro_costos}</td>
                  <td>{item.direccion_ejecutiva}</td>
                  <td>
                    {item.fecha_contratacion !== null
                      ? moment.utc(item.updateAt).format('DD/MM/YYYY')
                      : ''}
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      )}
      <div className="pagination-container">
        <div className="row-numbers">
          <p>Filas por página:</p>
          <select value={rowsLimit} onChange={handleRowsLimit}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
          </select>
        </div>
        <div className="pagination">
          <p>
            {paginationInfo === null
              ? 'No pagination'
              : paginationInfo.page * paginationInfo.limit -
                (paginationInfo.limit - 1) +
                ' - ' +
                (paginationInfo.page * paginationInfo.limit <= paginationInfo.total
                  ? paginationInfo.page * paginationInfo.limit
                  : paginationInfo.total) +
                ' de ' +
                paginationInfo.total}
          </p>
          <div className="pagination-arrow">
            <img src={LeftArrow} alt="left=arrow" onClick={previousPage} />
            <img src={RightArrow} alt="right-arrow" onClick={nextPage} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Yastas;

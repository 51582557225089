import React, { memo, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  getShadowDetailsAction,
  ShadowProcessDetailI
} from '../../../redux/pdfSombra/pdfSombraActions';
import { useDispatch } from 'react-redux';
import { getDetallesPdfSombra } from '../../../services/getDetallesPdfSombra';

const useRenderPdfSombra = () => {
  const [pdfDetails, setpdfDetails] = useState<ShadowProcessDetailI | undefined>(undefined);
  const [loading, setloading] = useState(true);
  const [searchParams] = useSearchParams();
  const query = useParams();
  const dispatch = useDispatch();
  const crumbs = [
    { path: `/trackAnual?${searchParams?.get('prev')}`, title: 'Track Capacitación' },
    {
      path: `/trackAnual/detalledeTrackCapacitacion/${query.nomina}?${searchParams?.get('prev')}`,
      title: 'Detalle del Track Capacitación'
    },
    {
      path: `/trackAnual/detalledeTrackCapacitacion/${query.nomina}?${searchParams?.get('prev')}/pdf-sombra`,
      title: 'pdf sombra'
    }
  ];

  useEffect(() => {
    const getShadowDetails = async () => {
      const token = sessionStorage.getItem('token');
      const id = query.nomina;
      if (token === null || !id) return;
      try {
        const { proceso_sombra }: { proceso_sombra: ShadowProcessDetailI } =
          await getDetallesPdfSombra(token, id);
        dispatch(getShadowDetailsAction(proceso_sombra));
        setpdfDetails(proceso_sombra);
      } catch (error) {
        console.log(error);
      } finally {
        setloading(false);
      }
    };
    getShadowDetails();
  }, [query]);
  return { crumbs, loading, pdfDetails };
};

export default useRenderPdfSombra;

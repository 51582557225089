import React, { useEffect, useState } from 'react';
import {  useParams, useSearchParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import UserFallback from '../assets/user-fallback-black.svg';
import Download from '../assets/download-logo.png';
import CapacitacionStyles from '../styles/Detalles.module.css';
import BreadCrumb from '../components/breadCrumb/breadCrumb';

import { getDetallesCapacitacion } from '../services/getDetallesCapacitacion';
import { downloadDetallesCapacitacion } from '../services/downloaddetallesC';
import moment from 'moment';
import '../styles/detallesCapacitacion.scss';
import LevelCards from '../components/LevelCards/LevelCards';
// Dentro de tu componente DetallesCapacitacion
interface avance {
  nivel: string;
  fecha_fin: Date | null;
  porcentaje: number;
  estatus: string;
  calificacion?: number;
  estado_finalizacion?: string;
  updatedAt: Date | null;
}

interface Colaborador {
  nomina: number;
  cursos: avance[];
  os: string;
  fechaFin: string;
  nombre: string;
  puesto: string;
  fin_curso_basico: string;
  porcentaje: number;
  direccion: string;
  subdireccion: string;
  proceso_sombra: boolean;
  proceso_sombra_status?: ProcesoSombraStatus;
  avances: avance[];
}
export interface ProcesoSombraStatus {
  base_par: boolean;
  base_lider: boolean;
}

const DetallesCapacitacion = () => {
  const [avances, setAvances] = useState<avance[]>([]);
  const [colaborador, setColaborador] = useState<Colaborador | null>(null);
  const [searchParams] = useSearchParams();
  const { nomina } = useParams<{ nomina: string }>();

  useEffect(() => {
    const nominaNumber = nomina ? parseInt(nomina, 10) : null;
    const token = sessionStorage.getItem('token');

    const fetchData = async () => {
      if (token && nominaNumber) {
        try {
          const result = await getDetallesCapacitacion(token, nominaNumber);
          setColaborador(result.capacitacion);
          setAvances(result.capacitacion.avances);
        } catch (error) {
          console.error('Error al obtener los detalles de capacitación', error);
        }
      }
    };

    fetchData();
  }, [nomina]);
  // termina api

  const crumbs = [
    { path: `/trackAnual?${searchParams?.get('prev')}`, title: 'Track Capacitación' },
    { path: '/reportes', title: 'Detalle del Track Capacitación' }
  ];

  const getProgressColor = (percentage: number) => {
    let color;
    if (percentage === 100) {
      color = '#4ca1af'; // Azul claro para completado
    } else if (percentage >= 75) {
      color = '#56ab2f'; // Verde para casi completo
    } else if (percentage >= 50) {
      color = '#f7b733'; // Amarillo para mitad de camino
    } else if (percentage >= 25) {
      color = '#ff8008'; // Naranja para progreso inicial
    } else if (percentage >= 10) {
      color = '#ff416c'; //  Rojo alerta roja
    } else if (percentage > 0) {
      color = '#630000'; //  Rojo alerta roja
    } else {
      color = '#e0e0e0'; // Rojo para progreso bajo
    }

    // Convertir el color a RGBA para añadir opacidad
    const colorOpaque = convertHexToRGBA(color, 0.4);

    return {
      color,
      colorOpaque
    };
  };

  // Función auxiliar para convertir el color hex a RGBA
  const convertHexToRGBA = (hex: string, opacity: number) => {
    const tempHex = hex.replace('#', '');
    const r = parseInt(tempHex.substring(0, 2), 16);
    const g = parseInt(tempHex.substring(2, 4), 16);
    const b = parseInt(tempHex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity})`;
  };

  const downloadDetalles = async () => {
    const token = sessionStorage.getItem('token');
    const nominaNumber = nomina ? parseInt(nomina, 10) : null;

    if (token && nominaNumber) {
      downloadDetallesCapacitacion(token, nominaNumber)
        .then((result) => {
          // Asumiendo que 'result' contiene un objeto con una propiedad 'url' que apunta a la ubicación del archivo
          window.open(result.url, '_blank'); // Asegúrate de abrir la URL en una nueva pestaña
        })
        .catch((error) => {
          console.error('Error al descargar los detalle de TrackAnual', error);
        });
    } else {
      console.error('Token no disponible o ID de nómina no especificado');
    }
  };

  const cursosCompletados = avances.filter((avance) => avance.estatus === 'Completado');
  const totalCursosCompletados = cursosCompletados.length;

  const ultimoCursoNoCompletado = avances
    .slice()
    .reverse()
    .find(
      (avance) => avance.estatus !== 'Completado' && avance.estatus !== 'Pendiente de asignación'
    );

  const totalCursosSinAsignar = avances.filter(
    (avance) => avance.estatus === 'Pendiente de asignación'
  ).length;

  return (
    <>
      <div className={CapacitacionStyles.container}>
        <BreadCrumb crumbs={crumbs} />
        <div className={CapacitacionStyles.principal}>
          <div className={CapacitacionStyles.flexColumnLeft}>
            <div className={CapacitacionStyles.cardColumn}>
              {colaborador ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '4px'
                  }}
                >
                  <img src={UserFallback} height={45} width={45} />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-around'
                    }}
                  >
                    <p
                      className={CapacitacionStyles.pBoldBlack}
                      style={{ margin: '0', marginLeft: '5px' }}
                    >
                      {colaborador.nombre}
                    </p>
                    <p
                      className={CapacitacionStyles.ppuesto}
                      style={{ margin: '5px', marginLeft: '5px' }}
                    >
                      {colaborador.puesto} | Nómina: {colaborador.nomina}
                    </p>
                    {/* Continuar mostrando más datos según se necesite */}
                  </div>
                </div>
              ) : (
                <p>Sin datos</p>
              )}
            </div>

            <div className={CapacitacionStyles.cardColumn}>
              <div className={CapacitacionStyles.pos}>
                OS:{' '}
                <span className={CapacitacionStyles.valorOs}>{colaborador?.os || 'Sin datos'}</span>
              </div>
              <div className={CapacitacionStyles.pos}>
                Fin Nivel Básico:{' '}
                <span className={CapacitacionStyles.valorFechaFin}>
                  {colaborador?.fin_curso_basico !== null
                    ? colaborador?.fin_curso_basico
                      ? `${moment.utc(colaborador?.fin_curso_basico)?.format('DD[/]MM[/]YYYY')}`
                      : 'SIN DATOS'
                    : 'SIN FECHA'}
                </span>
              </div>
            </div>
            <div className={CapacitacionStyles.cardColumn}>
              <div className={CapacitacionStyles.pos}>
                Dirección:{' '}
                <span className={CapacitacionStyles.valorOs}>
                  {colaborador?.direccion || 'Sin datos'}
                </span>
              </div>
              <div className={CapacitacionStyles.pos}>
                SubDirección:{' '}
                <span className={CapacitacionStyles.valorOs}>
                  {colaborador?.subdireccion || 'Sin datos'}
                </span>
              </div>
            </div>
            <div className={CapacitacionStyles.cardColumn}>
              <div className={CapacitacionStyles['imgPointer']} onClick={downloadDetalles}>
                <img src={Download} alt="upload-logo" />
                <p style={{ marginLeft: '5px' }}>DESCARGAR TRACK</p>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div
              className={CapacitacionStyles.flexColumnLeft}
              style={{ justifyContent: 'flex-start', width: '100%' }}
            >
              <p
                className={CapacitacionStyles.ppuestolevel}
                style={{ margin: '0', textAlign: 'left' }}
              >
                {colaborador?.porcentaje === 100
                  ? 'El colaborador ha concluido su track'
                  : ultimoCursoNoCompletado
                    ? `El Colaborador se encuentra en el curso ${ultimoCursoNoCompletado.nivel}`
                    : totalCursosSinAsignar > 0
                      ? `El colaborador está próximo a iniciar el curso ${avances.find((avance) => avance.estatus === 'Pendiente de asignación')?.nivel}`
                      : totalCursosCompletados > 0 &&
                          avances.every((avance) => avance.estatus === 'Completado')
                        ? `El colaborador ha completado ${totalCursosCompletados} curso${totalCursosCompletados > 1 ? 's' : ''}.`
                        : totalCursosSinAsignar > 0 &&
                            avances.every((avance) => avance.estatus === 'Pendiente de asignación')
                          ? `El colaborador no tiene otros cursos asignados.`
                          : 'No hay datos de curso'}
              </p>
            </div>
            <span> {colaborador?.porcentaje.toFixed(2)}%</span>
          </div>
          <div
            className={CapacitacionStyles.progressContainer}
            style={{
              backgroundColor: getProgressColor(colaborador?.porcentaje ?? 0).colorOpaque,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <div
              className={CapacitacionStyles.progressBar}
              style={{
                width: `${colaborador?.porcentaje}%`,
                backgroundColor: getProgressColor(colaborador?.porcentaje ?? 0).color
              }}
            ></div>
          </div>
        </div>

        <LevelCards avances={avances} colaborador={colaborador} />
      </div>
    </>
  );
};

export default DetallesCapacitacion;

import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/userReducer';
import pdfReducer from './pdfSombra/pdfSombraReducer';


const store = configureStore({
  reducer: {
    user: userReducer,
    pdfSombra: pdfReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
});

export default store;
import React, { useState, useEffect } from 'react';
import Upload from '../assets/upload-logo.png';
import LeftArrow from '../assets/arrow-left.png';
import RightArrow from '../assets/arrow-right.png';
import SuccessLogo from '../assets/success-logo.png';
import Quit from '../assets/quit.png';
import ErrorLogo from '../assets/error-logo.png';
import QuestionMark from '../assets/circle-info.svg';
import UploadModal from '../components/uploadModal/uploadModal';
import { uploadExcelHoteles } from '../services/uploadExcelHoteles';
import { getHotelesFiltrados } from '../services/getHotelesFiltrados';
import moment from 'moment';

function Hoteles() {
  const [hoteles, setHoteles] = useState<any>(null);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [rowsLimit, setRowsLimit] = useState<number | ''>(10);
  const [eventoFilter, setEventoFilter] = useState<string>('');
  const [paginationInfo, setPaginationInfo] = useState<any>(null);
  const [pagination, setPagination] = useState<number>(1);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadHotelesSuccess, setUploadHotelesSuccess] = useState<boolean>(false);
  const [uploadHotelesError, setUploadHotelesError] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [actionName, setActionName] = useState<string>('');
  const [uploadingMessage, setUploadingMessage] = useState('Cargando Datos...');

  const closeAlert = () => {
    setIsUploading(false);
  };

  const closeLoadingAlert = () => {
    setLoadingData(false);
  };

  const handleCargarHotelesClick = () => {
    setActionName('hoteles');
    setUpdateModal(true);
  };

  const handleRowsLimit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue: number | '' = e.target.value ? parseInt(e.target.value, 10) : '';
    setRowsLimit(newValue);
  };

  const nextPage = () => {
    if (pagination < paginationInfo.totalPages) {
      setPagination(pagination + 1);
    }
  };

  const previousPage = () => {
    if (pagination > 1) {
      setPagination(pagination - 1);
    }
  };

  const onClose = () => {
    setUpdateModal(false);
  };

  const handleFileUpload = async (base64Data: string, fileName: string) => {
    setLoadingData(true);
    setIsUploading(true);
    setUploadingMessage('Cargando Datos...');

    setTimeout(() => {
      setLoadingData(false);
      setUploadingMessage('Se subirá el excel pronto');

      setTimeout(async () => {
        const token = sessionStorage.getItem('token');

        if (!token) {
          setErrorMessage('Error: No se encontró el token de sesión.');
          setUploadHotelesError(true);
          setIsUploading(false);
          setTimeout(closeAlert, 2000);
          return;
        }

        let response;
        try {
          if (actionName === 'hoteles') {
            response = await uploadExcelHoteles(base64Data, token);
          } else {
            throw new Error('Acción no reconocida');
          }

          setIsUploading(false);

          if (response && response.success) {
            setSuccessMessage(`La subida del archivo ${fileName} fue exitosa.`);
            setUploadHotelesSuccess(true);
          } else {
            setErrorMessage(response.message || 'Error en la carga del archivo.');
            setUploadHotelesError(true);
          }
        } catch (error) {
          const errorMessage =
            error instanceof Error ? error.message : 'Error inesperado en la carga del archivo.';
          setErrorMessage(errorMessage);
          setUploadHotelesError(true);
        } finally {
          setTimeout(closeAlert, 2000);
        }
      }, 2000);
    }, 2000);
  };

  useEffect(() => {
    const fetchHoteles = async () => {
      const token = sessionStorage.getItem('token');
      if (token) {
        try {
          const result = await getHotelesFiltrados(token, rowsLimit, pagination);
          setHoteles(result.data.hoteles);
          setPaginationInfo(result.data.pagination);
        } catch (error) {
          console.error('Error al obtener hoteles', error);
        }
      }
    };
    fetchHoteles();
  }, [rowsLimit, pagination]);

  return (
    <div className="container colaboradores">
      <div className="table-options">
        <div className="option" onClick={handleCargarHotelesClick}>
          <img src={Upload} alt="upload-logo" />
          <p>ACTUALIZAR BASE DE DATOS</p>
        </div>
        {updateModal && (
          <UploadModal
            onClose={onClose}
            uploadService={handleFileUpload}
            title={`Cargar Archivo de ${actionName.charAt(0).toUpperCase() + actionName.slice(1)}`}
            isLoading={isUploading}
            setIsLoading={setLoadingData}
            successCallback={(message) => {
              console.log(message);
            }}
            errorCallback={(message) => {
              console.error(message);
            }}
          />
        )}
      </div>
      {loadingData === true && (
        <div className="update-file-response-container">
          <div className="update-file-message loading-data">
            <img src={SuccessLogo} alt="success-logo" />
            <p>Cargando Datos...</p>
            <img src={Quit} alt="quit-logo" onClick={closeLoadingAlert} className="close-alert" />
          </div>
        </div>
      )}
      {isUploading && (
        <div className="update-file-response-container-info">
          <div className="update-file-message">
            <img src={QuestionMark} />
            <p>{uploadingMessage}</p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      {uploadHotelesSuccess === true && (
        <div className="update-file-response-container-success">
          <div className="update-file-message update-file-success">
            <img src={SuccessLogo} alt="success-logo" />
            <p>{successMessage}</p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      {uploadHotelesError === true && (
        <div className="update-file-response-container-error">
          <div className="update-file-message update-file-error">
            <img src={ErrorLogo} alt="error-logo" />
            <p>{errorMessage}</p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      {hoteles === null ? (
        'No data'
      ) : (
        <div className="table-container scrollable-container">
          <table className="data-table-container">
            <thead className="table-header">
              <tr>
                <th>Sede</th>
                <th>Cadena</th>
                <th>Nombre</th>
                <th>Dirección</th>
                <th>Última actualización</th>
              </tr>
            </thead>
            {hoteles.map((hotel: any, index: number) => (
              <tbody key={index}>
                <tr className="data-table">
                  <td>{hotel.sede}</td>
                  <td>{hotel.cadena}</td>
                  <td>{hotel.nombre}</td>
                  <td>{hotel.direccion}</td>
                  <td>
                    {hotel.fecha_contratacion !== null
                      ? moment.utc(hotel.updateAt).format('DD/MM/YYYY')
                      : ''}
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      )}
      <div className="pagination-container">
        <div className="row-numbers">
          <p>Filas por página:</p>
          <select value={rowsLimit} onChange={handleRowsLimit}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
          </select>
        </div>
        <div className="pagination">
          <p>
            {paginationInfo === null
              ? 'No pagination'
              : paginationInfo.page * paginationInfo.limit -
                (paginationInfo.limit - 1) +
                ' - ' +
                (paginationInfo.page * paginationInfo.limit <= paginationInfo.total
                  ? paginationInfo.page * paginationInfo.limit
                  : paginationInfo.total) +
                ' de ' +
                paginationInfo.total}
          </p>
          <div className="pagination-arrow">
            <img src={LeftArrow} alt="left=arrow" onClick={previousPage} />
            <img src={RightArrow} alt="right-arrow" onClick={nextPage} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hoteles;

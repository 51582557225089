import React, { useState, useEffect } from 'react';
import Upload from '../assets/upload-logo.png';
import LeftArrow from '../assets/arrow-left.png';
import RightArrow from '../assets/arrow-right.png';
import SuccessLogo from '../assets/success-logo.png';
import Quit from '../assets/quit.png';
import ErrorLogo from '../assets/error-logo.png';
import QuestionMark from '../assets/circle-info.svg';
import UploadModal from '../components/uploadModal/uploadModal';
import { uploadExcelFormadores } from '../services/uploadExcelFormadores';
import { getFormadoresFiltrados } from '../services/getFormadoresFiltrados';
import moment from 'moment';

function GestionLideresFormadores() {
  const [formadores, setFormadores] = useState<any>(null);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [rowsLimit, setRowsLimit] = useState<number | ''>(10);
  const [eventoFilter, setEventoFilter] = useState<string>('');
  const [paginationInfo, setPaginationInfo] = useState<any>(null);
  const [pagination, setPagination] = useState<number>(1);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadFormadoresSuccess, setUploadFormadoresSuccess] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [uploadFormadoresError, setUploadFormadoresError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [actionName, setActionName] = useState<string>('');
  const [uploadingMessage, setUploadingMessage] = useState('Cargando Datos...');

  const closeAlert = () => {
    setIsUploading(false);
  };

  const closeLoadingAlert = () => {
    setLoadingData(false);
  };

  const handleCargarFormadoresClick = () => {
    setActionName('formadores');
    setUpdateModal(true);
  };

  const handleRowsLimit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue: number | '' = e.target.value ? parseInt(e.target.value, 10) : '';
    setRowsLimit(newValue);
  };

  const nextPage = () => {
    if (pagination < paginationInfo.totalPages) {
      setPagination(pagination + 1);
    }
  };

  const previousPage = () => {
    if (pagination > 1) {
      setPagination(pagination - 1);
    }
  };

  const onClose = () => {
    setUpdateModal(false);
  };

  const handleFileUpload = async (base64Data: string, fileName: string) => {
    setLoadingData(true);
    setIsUploading(true);
    setUploadingMessage('Cargando Datos...');

    setTimeout(() => {
      setLoadingData(false);
      setUploadingMessage('Se subirá el excel pronto');

      setTimeout(async () => {
        const token = sessionStorage.getItem('token');

        if (!token) {
          setErrorMessage('Error: No se encontró el token de sesión.');
          setUploadFormadoresError(true);
          setIsUploading(false);
          setTimeout(closeAlert, 2000);
          return;
        }

        let response;
        try {
          if (actionName === 'formadores') {
            response = await uploadExcelFormadores(base64Data, token);
          } else {
            throw new Error('Acción no reconocida');
          }

          setIsUploading(false);

          if (response && response.success) {
            setSuccessMessage(`La subida del archivo ${fileName} fue exitosa.`);
            setUploadFormadoresSuccess(true);
          } else {
            setErrorMessage(response.message || 'Error en la carga del archivo.');
            setUploadFormadoresError(true);
          }
        } catch (error) {
          const errorMessage =
            error instanceof Error ? error.message : 'Error inesperado en la carga del archivo.';
          setErrorMessage(errorMessage);
          setUploadFormadoresError(true);
        } finally {
          setTimeout(closeAlert, 2000);
        }
      }, 2000);
    }, 2000);
  };

  useEffect(() => {
    const fetchFormadores = async () => {
      const token = sessionStorage.getItem('token');
      if (token) {
        try {
          const result = await getFormadoresFiltrados(token, rowsLimit, pagination, eventoFilter);
          setFormadores(result.data.formadores);
          console.log('datos actuales', result.data.formadores);
          setPaginationInfo(result.data.pagination);
        } catch (error) {
          console.error('Error al obtener formadores', error);
        }
      }
    };
    fetchFormadores();
  }, [rowsLimit, eventoFilter, pagination, uploadFormadoresSuccess]);

  return (
    <div className="container colaboradores">
      <div className="table-options">
        <div className="option" onClick={handleCargarFormadoresClick}>
          <img src={Upload} alt="upload-logo" />
          <p>ACTUALIZAR BASE DE DATOS</p>
        </div>
        {updateModal && (
          <UploadModal
            onClose={onClose}
            uploadService={handleFileUpload} // Función para manejar la carga del archivo
            title={`Cargar Archivo de ${actionName.charAt(0).toUpperCase() + actionName.slice(1)}`} // Capitaliza la primera letra
            isLoading={isUploading}
            setIsLoading={setLoadingData}
            successCallback={(message) => {
              console.log(message);
            }}
            errorCallback={(message) => {
              console.error(message);
            }}
          />
        )}
      </div>
      {loadingData === true && (
        <div className="update-file-response-container">
          <div className="update-file-message loading-data">
            <img src={SuccessLogo} alt="success-logo" />
            <p>Cargando Datos...</p>
            <img src={Quit} alt="quit-logo" onClick={closeLoadingAlert} className="close-alert" />
          </div>
        </div>
      )}
      {isUploading && (
        <div className="update-file-response-container-info">
          <div className="update-file-message">
            <img src={QuestionMark} />
            <p>{uploadingMessage}</p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      {uploadFormadoresSuccess === true && (
        <div className="update-file-response-container-success">
          <div className="update-file-message update-file-success">
            <img src={SuccessLogo} alt="success-logo" />
            <p>{successMessage}</p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      {uploadFormadoresError === true && (
        <div className="update-file-response-container-error">
          <div className="update-file-message update-file-error">
            <img src={ErrorLogo} alt="error-logo" />
            <p>{errorMessage}</p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      {formadores === null ? (
        'No data'
      ) : (
        <div className="table-container scrollable-container">
          <table className="data-table-container">
            <thead className="table-header">
              <tr>
                <th>Nómina</th>
                <th>Formador</th>
                <th>Lider</th>
                <th className="colabs-header-2">OS</th>
                <th>DRV</th>
                <th>SRV</th>
                <th>Estado OS</th>
                <th>Última actualización</th>
              </tr>
            </thead>
            {formadores.map((item: any, index: number) => (
              <tbody key={index}>
                <tr className="data-table">
                  <td>{item.nomina}</td>
                  <td>{item.formador}</td>
                  <td>{item.lider}</td>
                  <td className="colabs-table-2">{item.os}</td>
                  <td>{item.drv}</td>
                  <td>{item.srv}</td>
                  <td>{item.estado_os}</td>
                  <td>
                    {item.fecha_contratacion !== null
                      ? moment.utc(item.updateAt).format('DD/MM/YYYY')
                      : ''}
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      )}
      <div className="pagination-container">
        <div className="row-numbers">
          <p>Filas por página:</p>
          <select value={rowsLimit} onChange={handleRowsLimit}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
          </select>
        </div>
        <div className="pagination">
          <p>
            {paginationInfo === null
              ? 'No pagination'
              : paginationInfo.page * paginationInfo.limit -
                (paginationInfo.limit - 1) +
                ' - ' +
                (paginationInfo.page * paginationInfo.limit <= paginationInfo.total
                  ? paginationInfo.page * paginationInfo.limit
                  : paginationInfo.total) +
                ' de ' +
                paginationInfo.total}
          </p>
          <div className="pagination-arrow">
            <img src={LeftArrow} alt="left=arrow" onClick={previousPage} />
            <img src={RightArrow} alt="right-arrow" onClick={nextPage} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GestionLideresFormadores;

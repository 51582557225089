const initialState = {
  shadowProcess: undefined,
  dynamicHeight: 80,
  dynamicHeight2: 80
};

const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SHADOW_DETAILS':
      return {
        ...state,
        shadowProcess: action.payload
      };
    case 'dynamicHeight':
      return {
        ...state,
        dynamicHeight: action.payload
      };
    case 'dynamicHeight2':
      return {
        ...state,
        dynamicHeight2: action.payload
      };
    default:
      return state;
  }
};

export default userReducer;

import React, { useState, useEffect } from 'react';
import Upload from '../assets/upload-logo.png';
import LeftArrow from '../assets/arrow-left.png';
import RightArrow from '../assets/arrow-right.png';
import SuccessLogo from '../assets/success-logo.png';
import Quit from '../assets/quit.png';
import ErrorLogo from '../assets/error-logo.png';
import UpdateSedesModal from '../components/updateSedesModal/updateSedesModal';
import { getSedesTabla } from '../services/getSedesTabla';
import moment from 'moment';

function Sedes() {
  const [sedes, setSedes] = useState<any>(null);
  const [updateSedesModal, setUpdateSedesModal] = useState<boolean>(false);
  const [updateSedesResponse, setUpdateSedesResponse] = useState<boolean | null>(null);
  const [rowsLimit, setRowsLimit] = useState<number | ''>(10);
  const [eventoFilter, setEventoFilter] = useState<string>('');
  const [paginationInfo, setPaginationInfo] = useState<any>(null);
  const [pagination, setPagination] = useState<number>(1);
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const isUpdateSedesModal = (openModal: boolean) => {
    setUpdateSedesModal(openModal);
  };

  //   const isfiltersModal = (openFiltersModal: boolean) => {
  //     setFiltersModal(openFiltersModal);
  //   };

  //   const isEventoFilter = (eventoFilter: string) => {
  //     setEventoFilter(eventoFilter);
  //   };

  const isPostSuccess = (postResponse: boolean) => {
    setUpdateSedesResponse(postResponse);
  };

  const isLoadingAlert = (loadAlert: boolean) => {
    setLoadingData(loadAlert);
  };

  const closeAlert = () => {
    setUpdateSedesResponse(null);
  };

  const closeLoadingAlert = () => {
    setLoadingData(false);
  };

  const handleRowsLimit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue: number | '' = e.target.value ? parseInt(e.target.value, 10) : '';
    setRowsLimit(newValue);
  };

  const nextPage = () => {
    if (pagination < paginationInfo.totalPages) {
      setPagination(pagination + 1);
    }
  };

  const previousPage = () => {
    if (pagination > 1) {
      setPagination(pagination - 1);
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const apiCall = () => {
      if (token) {
        getSedesTabla(token, rowsLimit, pagination, eventoFilter)
          .then((result) => {
            setSedes(result.data.sedes);
            setPaginationInfo(result.data.pagination);
          })
          .catch((error) => {
            console.error('Error al obtener usuario', error);
          });
      }
    };
    apiCall();
    if (updateSedesResponse === true) {
      apiCall();
      setLoadingData(false);
      setTimeout(() => {
        setUpdateSedesResponse(null);
      }, 5000);
    }
    if (updateSedesResponse === false) {
      setLoadingData(false);
      setTimeout(() => {
        setUpdateSedesResponse(null);
      }, 5000);
    }
  }, [rowsLimit, eventoFilter, pagination, updateSedesResponse]);

  return (
    <div className="container colaboradores">
      <div className="table-options">
        <div className="option" onClick={() => setUpdateSedesModal(true)}>
          <img src={Upload} alt="upload-logo" />
          <p>ACTUALIZAR BASE DE DATOS</p>
        </div>
        {updateSedesModal && (
          <UpdateSedesModal
            isUpdateSedesModal={isUpdateSedesModal}
            isPostSuccess={isPostSuccess}
            isLoadingAlert={isLoadingAlert}
          />
        )}
        {/* {filtersModal && (
          <ColabsFilterModal isfiltersModal={isfiltersModal} isEventoFilter={isEventoFilter} />
        )} */}
        {/* <div className="option">
          <img src={Filter} alt="filter-logo" />
          <p>FILTRAR</p>
        </div> */}
      </div>
      {loadingData === true && (
        <div className="update-file-response-container">
          <div className="update-file-message loading-data">
            <img src={SuccessLogo} alt="success-logo" />
            <p>Cargando Datos...</p>
            <img src={Quit} alt="quit-logo" onClick={closeLoadingAlert} className="close-alert" />
          </div>
        </div>
      )}
      {updateSedesResponse === true && (
        <div className="update-file-response-container">
          <div className="update-file-message update-file-success">
            <img src={SuccessLogo} alt="success-logo" />
            <p>La base de datos se actualizó satisfactoriamente</p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      {updateSedesResponse === false && (
        <div className="update-file-response-container">
          <div className="update-file-message update-file-error">
            <img src={ErrorLogo} alt="error-logo" />
            <p>
              Hubo un problema con el sistema y la base de datos no se actualizó, por favor,
              inténtalo de nuevo
            </p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      {sedes === null ? (
        'No data'
      ) : (
        <div className="table-container scrollable-container">
          <table className="data-table-container">
            <thead className="table-header-sedes">
              <tr>
                <th>OS</th>
                <th>Sede 1</th>
                <th>Sede 2</th>
                <th>Última actualización</th>

              </tr>
            </thead>
            {sedes.map((item: any, index: number) => (
              <tbody key={index}>
                <tr className="data-table-sedes">
                  <td>{item.os}</td>
                  <td>{item.sede1}</td>
                  <td>{item.sede2}</td>
                  
                  <td>
                        {item.fecha_contratacion !== null
                          ? moment.utc(item.updateAt).format('DD/MM/YYYY')
                          : ''}
                      </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      )}
      <div className="pagination-container">
        <div className="row-numbers">
          <p>Filas por página:</p>
          <select value={rowsLimit} onChange={handleRowsLimit}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
          </select>
        </div>
        <div className="pagination">
          <p>
            {paginationInfo === null
              ? 'No pagination'
              : paginationInfo.page * paginationInfo.limit -
                (paginationInfo.limit - 1) +
                ' - ' +
                (paginationInfo.page * paginationInfo.limit <= paginationInfo.total
                  ? paginationInfo.page * paginationInfo.limit
                  : paginationInfo.total) +
                ' de ' +
                paginationInfo.total}
          </p>
          <div className="pagination-arrow">
            <img src={LeftArrow} alt="left=arrow" onClick={previousPage} />
            <img src={RightArrow} alt="right-arrow" onClick={nextPage} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sedes;

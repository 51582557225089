import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../types/types';
const estiloCelda = {
  backgroundColor: 'red',
  color: 'white',
  fontWeight: 'bold',
  border: '1px solid gray'
};
const headerStyle: any = {
  ...estiloCelda,
  textAlign: 'center'
};
const Table1 = memo(() => {
  const { shadowProcess } = useSelector((state: AppState) => state.pdfSombra);
  return (
    <div className="table-one content">
      <div className="row">
        <div className="col-1" style={{fontSize:'15px'}}>N° {shadowProcess?.nomina}</div>
        <div className="col-5" style={{fontSize:'15px'}}> Colaborador: {shadowProcess?.nombre}</div>
        <div className="col-3" style={{fontSize:'15px'}}> {shadowProcess?.puesto}</div>
      </div>
      <div className="dvider" />

      <div className="row">
        <div className="col-1">N° {shadowProcess?.nomina_lider}</div>
        <div className="col-5">Jefe Inmediato: {shadowProcess?.nombre_lider}</div>
        <div className="col-3"></div>
      </div>

      <div className="row">
        <div className="col-1">N° {shadowProcess?.nomina_encargado}</div>
        <div className="col-5">Par experto: {shadowProcess?.nombre_encargado}</div>
        <div className="col-3"></div>
      </div>
    </div>
  );

});
Table1.displayName = 'Table1';

export default Table1;
